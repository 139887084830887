<template>
  <div
    class="Contact Card"
    data-testid="ProfileCard"
  >
    <div class="Card__header has-background p-v-m is-size-3 is-flex is-aligned-justify is-aligned-middle">
      <h4>{{ profile.preferred_name }}</h4>
    </div>

    <div class="Card__body has-background-grey-lighter">
      <div class="columns">
        <div class="Contact__details column is-3">
          <round-avatar
            :src="profile.picture_url"
            :acronym-fallback="profile.preferred_name"
            :fallback="$asset('/images/general/generic_avatar_placeholder.jpg')"
            size="large"
          />
        </div>

        <div class="Contact__extra column is-9">
          <div class="Contact__extra__brand">
            <h5>
              <i class="hf hf-fw hf-person" />
              <span v-if="profile.associations && profile.associations.length && profile.associations[0].role">{{ ucfirst(truncate(profile.associations[0].role, 50)) }}</span>
              <span v-else>{{ $t('general.journalist') }}</span>
            </h5>

            <h5>
              <i class="hf hf-fw hf-house" />
              <span v-if="profile.associations && profile.associations.length && profile.associations[0].association">{{ ucfirst(truncate(profile.associations[0].association, 50)) }}</span>
              <span v-else>{{ $t('general.not_available') }}</span>
            </h5>

            <h5 v-if="profile.locations && profile.locations.length">
              <country-flag :country="profile.locations[0].country_code" class="nationality" />
              <span>{{ getCountryNameByIsoCode2(profile.locations[0].country_code) }}</span>
            </h5>
            <h5 v-else>
              <em>Country not detected</em>
            </h5>
          </div>
        </div>
      </div>

      <div class="columns is-multiline m-b-none">
        <div class="Contact__details is-12-tablet column is-3-desktop">
          <social-profiles :source="profile" :primary-only="true" />
        </div>

        <div class="Contact__social column is-12-tablet is-9-desktop is-flex is-aligned-middle">
          <social-profiles :source="profile" :secondary-only="true" />

          <div class="m-l-a">
            <button
              v-show="showSuccessButton"
              class="Contact__add button is-small is-success"
              disabled
            >
              {{ $t('forms.added') }}
            </button>

            <restricted-button
              v-show="!showSuccessButton"
              :loading="loading"
              :disabled="loading"
              data-testid="addToRelations"
              permission="relations.discover.save-as-relation"
              action-class="Contact__add button is-black"
              @click.prevent="selectBrands"
            >
              {{ $t('components.media_profile_card.add_to_relations') }}
            </restricted-button>
          </div>
        </div>
      </div>

      <div class="columns m-t-xs m-b-xs border-t-grey-light" style="overflow: auto;">
        <div class="column is-6">
          <div class="Contact__categories">
            <p class="p-b-xs">
              <v-icon type="heart" />
              {{ $t('general.categories') }}:
            </p>

            <template v-if="profile.categories && profile.categories.length">
              <span v-for="(category, index) in categories" :key="index" class="Tag">
                {{ category }}
              </span>
            </template>
            <span v-else class="fallback">{{ $t('general.not_available') }}</span>
          </div>
        </div>

        <div class="column is-6">
          <div class="Contact__topics">
            <p class="p-b-xs">
              <v-icon type="tag" />
              {{ $t('general.tags') }}:
            </p>

            <template v-if="profile.tags && profile.tags.length">
              <span
                v-for="(topic, index) in profile.tags"
                :key="index"
                :title="topic"
                class="Tag is-light"
              >
                {{ truncate(topic, 20) }}
              </span>
            </template>
            <span v-else class="fallback">{{ $t('general.not_available') }}</span>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      :visible.sync="displaySelectBrandModal"
      data-testid="selectBrandModal"
      title="Select brands"
      width="30%"
    >
      <ul class="m-b-l">
        <li v-for="brand in brands" :key="brand.id" class="Select-brand">
          <el-checkbox-group v-model="selectedBrands">
            <el-checkbox :label="brand.id">
              <round-avatar
                :src="brand.logo.cropped || brand.logo.original"
                :acronym-fallback="brand.name"
                size="small"
              />

              {{ brand.name }}
            </el-checkbox>
          </el-checkbox-group>
        </li>
      </ul>

      <hr class="m-v-l">

      <div class="is-flex is-aligned-end">
        <button
          data-testid="selectAll"
          class="button is-secondary m-r-a"
          @click="selectAllBrands"
        >
          {{ $t('components.media_profile_card.select_all') }}
        </button>

        <button class="Contact__add button is-text" @click.prevent="displaySelectBrandModal = false">
          {{ $t('forms.cancel') }}
        </button>

        <button
          :class="{'is-loading': loading}"
          :disabled="loading"
          data-testid="addToSelectedBrands"
          class="Contact__add button is-primary"
          @click.prevent="addToRelations"
        >
          {{ $t('components.media_profile_card.add_to_relations_selected_brand') }}
        </button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ucfirstMixin from '@hypefactors/shared/js/mixins/UcfirstMixin'
import TruncateMixin from '@hypefactors/shared/js/mixins/TruncateMixin'

import SocialProfiles from '@/components/connect/_shared/SocialProfiles'

export default {
  components: {
    SocialProfiles
  },

  mixins: [ucfirstMixin, TruncateMixin],

  props: {
    profile: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    loading: false,
    showSuccessButton: false,
    displaySelectBrandModal: false,
    selectedBrands: []
  }),

  computed: {
    ...mapGetters(['brands', 'getCountryNameByIsoCode2']),

    categories () {
      return this.profile.categories.map((category) => this.$t(`connect-categories.${category}`))
    }
  },

  methods: {
    selectBrands () {
      if (this.brands.length > 1) {
        this.displaySelectBrandModal = true
      } else {
        this.selectedBrands[0] = this.brands[0].id

        this.addToRelations()
      }
    },

    selectAllBrands () {
      this.selectedBrands = this.brands.map(b => b.id)
    },

    addToRelations () {
      let calls = this.selectedBrands.map((brand) => {
        return this.$api.post(`/connect/relations?brand=${brand}`, {
          profile_id: this.profile.id
        })
      })

      if (!calls.length) {
        this.$notify({
          title: this.$t('general.error'),
          message: this.$t('errors.select_one_brand'),
          type: 'error'
        })

        this.loading = false
        return false
      }

      this.loading = true

      Promise.all(calls)
        .then((response) => {
          const relationId = response[0].data.data.id

          this.loading = false
          this.showSuccessButton = true
          this.displaySelectBrandModal = false

          this.$emit('relation-added', this.profile)

          this.$notifyWithLink({
            to: { name: 'connect.relation.view', params: { relationId: relationId } },
            message: this.$t('success.relation_added'),
            linkContent: this.$t('general.click_to_preview')
          })
        })
        .catch(error => {
          this.loading = false
          this.displaySelectBrandModal = false

          this.$notify({
            title: this.$t('general.error'),
            message: this.$t('errors.cannot_add_relation', { message: error.message }),
            type: 'error'
          })
        })
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.Contact {
  @include transit(color);

  .nationality {
    width: 12px;
    height: 12px;
    object-fit: cover;
    margin-right: $margin-small;
    border-radius: 100%;
  }

  .Contact__extra {
    &__brand {
      height: 100%;
    }

    h5 {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: $margin-small;
      margin-left: $margin-tiny;
      font-weight: bold;
      text-align: left;

      i {
        margin-right: $margin-tiny;
        font-size: $hf__font-label;
        color: $hf__text-muted;
        @include rtl {
          margin-left: $margin-tiny;
          margin-right: 0;
        }
      }
    }
  }

  &__topics,
  &__categories {
    height: 5.4rem;
    margin: 0;

    p {
      margin: $margin-tiny;
      margin-bottom: 0;
      font-size: $hf__font-label;
      font-weight: bold;
      text-transform: uppercase;
    }

    .fallback {
      padding: 0.1rem $padding-small;
      margin: $margin-tiny;
      font-size: $hf__font-label;
      color: $hf__text-muted;
    }
  }

  &__topics {
    border-left: $hf__border--base;
  }

  .Card__header {
    h4 {
      font-weight: bold;
    }

    p {
      font-size: $hf__font-label;
    }
  }

  .Card__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: $hf__font-label-small;
      font-weight: bold;
      color: $hf__text-muted;
      text-transform: uppercase;
    }
  }

  .Select-brand {
    margin-bottom: $margin;

    .el-checkbox {
      display: flex;
      align-items: center;
    }

    .el-checkbox__label {
      display: flex;
      align-items: center;

      .Avatar {
        margin-right: $margin;
      }
    }
  }
}
</style>
